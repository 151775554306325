















import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    return {
      userManager,
      orderFields: [
        { value: "revenue", text: "DSDK MKT" },
        { value: "returnPercentage", text: "% Return" },
        { value: "count", text: "Tổng số đơn" },
      ],
      extraFiltersOptions: {
        count: {
          label: "Tổng số đơn",
          clearable: true,
          items: [
            {
              value: "",
              text: "Tất cả",
            },
            {
              value: "0_50",
              text: "0-50",
            },
            {
              value: "50_200",
              text: "50-200",
            },
            {
              value: "200_",
              text: ">200",
            },
          ],
        },
      },
    };
  },
});
